#react-autowhatever-1 {
  display: none;
  position: absolute;
  height: 24rem;
  width: calc(100vw - 3rem);
  left: 0;
  z-index: 5;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 0;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

@media (min-width: 640px) {
  #react-autowhatever-1 {
    width: calc(100vw - 5.2rem);
    margin-left: 2.6rem;
    margin-right: 2.6rem;
  }
}

@media (min-width: 771px) {
  #react-autowhatever-1 {
    width: 13.2rem;
    left: unset;
    margin-left: 0;
    margin-right: 0;
  }
}
#react-autowhatever-1 li {
  padding: 0.125rem 1rem;
  background: white;
}
#react-autowhatever-1 li:hover {
  cursor: pointer;
}
#react-autowhatever-1 .react-autosuggest__suggestion--highlighted {
  text-decoration: underline;
}
#react-autowhatever-1.react-autosuggest__suggestions-container--open {
  display: block;
}
