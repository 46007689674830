.bz-reset {
  all: revert; /* blocking inheritance for all properties */
}

@tailwind base;

@layer base {
  h1 {
    @apply font-semibold text-5xl
  }

  h2 {
    @apply font-semibold text-xl
  }

  h3 {
    @apply font-bold text-base
  }

  h4 {
    @apply font-bold text-base
  }
}

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

#purple-widget-boersenzeitung {
  @apply font-sans text-base
}

code {
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rdrDateRangePickerWrapper {
  justify-content: center;
}

.rdrDefinedRangesWrapper {
  display: none !important;
}

.rdrMonths {
  @apply md:flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .rdrMonths {
    flex-direction: row !important;
  }
}

@import "styles/auto-suggest-override.css";
@import "styles/email-notification.css";

