.root,
:root,
#root,
#docs-root {
    --e-mail-notification-primary-color: #af292e;
    --e-mail-notification-secondary-color: #7f7f7f;
    --e-mail-notification-positive-color: #399d03;
    --e-mail-notification-negative-color: #c11036;
    --e-mail-notification-separator-color: #bbb;
    --e-mail-notification-background-color: #efefef;
    --e-mail-notification-button-text-color: #ffffff;
}
