@font-face {
    font-family: 'Source Sans Pro';
    src: url('./styles/fonts/SourceSansPro-Regular.ttf')
    format('truetype');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./styles/fonts/SourceSansPro-SemiBold.ttf')
    format('truetype');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./styles/fonts/SourceSansPro-Bold.ttf')
    format('truetype');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./styles/fonts/SourceSansPro-Italic.ttf')
    format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('./styles/fonts/SourceSerifPro-SemiBold.ttf')
    format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url('./styles/fonts/SourceCodePro-SemiBold.ttf')
    format('truetype');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url('./styles/fonts/SourceCodePro-Bold.ttf')
    format('truetype');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'custom-icons';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./styles/fonts/custom-icons.woff2') format('woff2');
}
